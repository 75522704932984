import React from "react"
import { Accordion, Card } from "react-bootstrap"
import "../styles/Faq.css"

const Faq = ({ remote, CONFIGS }) => {
  return (
    <div className="faq">
      <h1 className="faq__title">
        {remote?.getValue(CONFIGS.FAQ_WELCOME)._value}
      </h1>
      <p className="faq__searchIntro">
        {remote?.getValue(CONFIGS.FAQ_SEARCH_INTRO)._value}
      </p>
      <Accordion defaultActiveKey="0" className="faq__list">
        {remote?.getValue(CONFIGS.FAQ_QAS)._value &&
          JSON.parse(remote?.getValue(CONFIGS.FAQ_QAS)._value)
            .sort()
            .map((obj, i) => (
              <Card key={"faq-card-" + i}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={i + 1}
                  className="faq__question"
                >
                  {obj.q}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={i + 1}>
                  <Card.Body className="faq__answer">{obj.a}</Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
      </Accordion>
    </div>
  )
}

export default Faq

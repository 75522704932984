import React from "react"
import "../styles/HomeSection.css"

const HomeSection = ({ title, paragraphs, image, image_alt, section }) => {
  return (
    <div className="homeSection">
      <h1>{title}</h1>
      <div className="homeSection__bottom">
        <div className="homeSection__text">
          {paragraphs &&
            JSON.parse(paragraphs).map((str, i) => (
              <p key={section + "-text-" + i}>{str}</p>
            ))}
        </div>
        <img className="homeSection__image" src={image} alt={image_alt} />
      </div>
    </div>
  )
}

export default HomeSection

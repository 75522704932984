import React, { useRef } from "react"
import { Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import "../styles/Header.css"

const Header = () => {
  const toggle = useRef(null)

  const closeCollapse = () => {
    toggle.current.click()
  }

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand>
        <Link to="/">
          <img
            className="header__logo"
            src="./Logo.svg"
            alt="Flan company logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle ref={toggle} />
      <Navbar.Collapse>
        <Nav className="ml-auto">
          <Link to="/" className="header__link" onClick={closeCollapse}>
            Home
          </Link>
          <Link to="/about" className="header__link" onClick={closeCollapse}>
            About
          </Link>
          <Link to="/faq" className="header__link" onClick={closeCollapse}>
            FAQ
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header

import "./App.css"
import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Header from "./components/Header"
import Home from "./components/Home"
import About from "./components/About"
import Faq from "./components/Faq"
import { remoteConfig, CONFIGS } from "./firebase"

function App() {
  const [remote, setRemote] = useState()

  useEffect(() => {
    remoteConfig.fetchAndActivate().then(() => {
      setRemote(remoteConfig)
    })
  }, [])

  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/about">
          <About remote={remote} CONFIGS={CONFIGS}></About>
        </Route>
        <Route path="/faq">
          <Faq remote={remote} CONFIGS={CONFIGS}></Faq>
        </Route>
        <Route path="/">
          <Home remote={remote} CONFIGS={CONFIGS} />
        </Route>
      </Switch>
    </Router>
  )
}

export default App

import firebase from "firebase"

const firebaseConfig = {
  apiKey: "AIzaSyD9VIcEYr0_lxAZ7fijCwvz_YuYc8hb2ik",
  authDomain: "flan-23a93.firebaseapp.com",
  databaseURL: "https://flan-23a93.firebaseio.com",
  projectId: "flan-23a93",
  storageBucket: "flan-23a93.appspot.com",
  messagingSenderId: "834466309602",
  appId: "1:834466309602:web:63fc23f58b3a354e2785bb",
  measurementId: "G-WV4V4R169T",
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

// Remote config setup

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings = {
  minimumFetchIntervalMillis: 100,
}

const CONFIGS = {
  LANDING_TITLE: "landing_title",
  LANDING_PRICE: "landing_price",
  MOBILE_TITLE: "mobile_title",
  CUSTOMERS_TITLE: "customers_title",
  OVERVIEW_TITLE: "overview_title",
  TODO_TITLE: "todo_title",
  MOBILE_TEXTS: "mobile_texts",
  CUSTOMERS_TEXTS: "customers_texts",
  OVERVIEW_TEXTS: "overview_texts",
  TODO_TEXTS: "todo_texts",
  LANDING_ABOUT_TITLE: "landing_about_title",
  LANDING_ABOUT_QUOTES: "landing_about_quotes",
  LANDING_ABOUT_TEXTS: "landing_about_texts",
  LANDING_ABOUT_ACTION: "landing_about_action",
  ABOUT_WELCOME: "about_welcome",
  ABOUT_INTRODUCTION: "about_introduction",
  ABOUT_TEXTS: "about_texts",
  FAQ_WELCOME: "faq_welcome",
  FAQ_SEARCH_INTRO: "faq_search_intro",
  FAQ_QAS: "faq_qas",
}

remoteConfig.defaultConfig = {
  [CONFIGS.LANDING_TITLE]: "Hello world! More info coming soon 🚀!",
  [CONFIGS.LANDING_PRICE]: "3,99",
}

remoteConfig.fetchAndActivate()

export { remoteConfig, CONFIGS }

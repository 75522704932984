import React, { useState } from "react"
import OverviewExample from "../assets/overview_preview.svg"
import ContactsExample from "../assets/contacts_preview.svg"
import TodoExample from "../assets/todo_preview.svg"
import Freelancer from "../assets/undraw_freelancer.svg"
import Background from "../assets/background_landing.svg"
import HomeSection from "./HomeSection"
import "../styles/Home.css"

const Home = ({ remote, CONFIGS }) => {
  const [email, setEmail] = useState("")
  const [subscribed, setSubscribed] = useState(false)

  const handleSignUp = (e) => {
    e.preventDefault()
    fetch(`${process.env.REACT_APP_MAILCHIMP_URL}&EMAIL=${email}`, {
      method: "POST",
    })
    setSubscribed(true)
  }

  return (
    <div className="home">
      <div className="home__landing">
        <h1 className="home__landingTitle">
          {remote?.getValue(CONFIGS.LANDING_TITLE)._value}
        </h1>
        <h4 className="home__landingPrice">
          $<em>{remote?.getValue(CONFIGS.LANDING_PRICE)._value}</em>/month
        </h4>
        <div className="home__preLaunch">
          {subscribed ? (
            <p className="home__landingBigP">
              Thanks for subscribing! we'll keep you posted!{" "}
            </p>
          ) : (
            <>
              <p className="home__landingBigP">We are almost launching! </p>
              <p>Want to be notified of our launch?</p>
              <p>Drop your mail below.</p>
              <div className="home__signUp">
                <form onSubmit={handleSignUp} autoComplete="on">
                  <input
                    type="email"
                    placeholder="example@mail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit" style={{ display: "none" }}></button>
                </form>
              </div>
            </>
          )}
        </div>
        <img
          className="home__backgroundImage"
          src={Background}
          alt="yellow curved background"
        />
      </div>
      <div className="home__about">
        <h3 className="home__aboutBigTitle">
          {remote?.getValue(CONFIGS.LANDING_ABOUT_TITLE)._value}
        </h3>
        <div className="home__aboutTop">
          <div className="home__aboutLeft">
            {remote?.getValue(CONFIGS.LANDING_ABOUT_QUOTES)._value &&
              JSON.parse(
                remote?.getValue(CONFIGS.LANDING_ABOUT_QUOTES)._value
              ).map((str, i) => (
                <p className="home__aboutQuote" key={"about-quote-" + i}>
                  {str}
                </p>
              ))}
            {remote?.getValue(CONFIGS.LANDING_ABOUT_TEXTS)._value &&
              JSON.parse(
                remote?.getValue(CONFIGS.LANDING_ABOUT_TEXTS)._value
              ).map((str, i) => (
                <p className="home__aboutText" key={"about-text-" + i}>
                  {str}
                </p>
              ))}
            <br />
          </div>
          <img
            className="home__aboutImage"
            src={Freelancer}
            alt="drawing of freelancer working on his laptop"
          />
        </div>
        <h4 className="home__aboutBigPrice">
          {remote?.getValue(CONFIGS.LANDING_ABOUT_ACTION)._value}
        </h4>
      </div>
      <HomeSection
        title={remote?.getValue(CONFIGS.MOBILE_TITLE)._value}
        paragraphs={remote?.getValue(CONFIGS.MOBILE_TEXTS)._value}
        image={OverviewExample}
        image_alt="screenshot of web and phone layout"
        section="mobile"
      />
      <HomeSection
        title={remote?.getValue(CONFIGS.CUSTOMERS_TITLE)._value}
        paragraphs={remote?.getValue(CONFIGS.CUSTOMERS_TEXTS)._value}
        image={ContactsExample}
        image_alt="screenshot of customers page on web and mobile"
        section="customers"
      />
      <HomeSection
        title={remote?.getValue(CONFIGS.OVERVIEW_TITLE)._value}
        paragraphs={remote?.getValue(CONFIGS.OVERVIEW_TEXTS)._value}
        image={OverviewExample}
        image_alt="screenshot of overview page on web and mobile"
        section="overview"
      />
      <HomeSection
        title={remote?.getValue(CONFIGS.TODO_TITLE)._value}
        paragraphs={remote?.getValue(CONFIGS.TODO_TEXTS)._value}
        image={TodoExample}
        image_alt="screenshot of todo page on web and mobile"
        section="todo"
      />
    </div>
  )
}

export default Home

import React from "react"
import Logo from "../assets/logo.svg"
import "../styles/About.css"

const About = ({ remote, CONFIGS }) => {
  return (
    <div className="about">
      <div className="about__top">
        <h1 className="about__welcome">
          {remote?.getValue(CONFIGS.ABOUT_WELCOME)._value}
        </h1>
        <h1 className="about__title">
          {remote?.getValue(CONFIGS.ABOUT_INTRODUCTION)._value}
        </h1>
      </div>
      <div className="about__bottom">
        <img
          className="about__image"
          src={Logo}
          alt="Company logo, yellow illustration of a flan desert"
        />
        <div className="about__left">
          {remote?.getValue(CONFIGS.ABOUT_TEXTS)._value &&
            JSON.parse(remote?.getValue(CONFIGS.ABOUT_TEXTS)._value).map(
              (str, i) => (
                <p className="about__paragraph" key={"about-page-text-" + i}>
                  {str}
                </p>
              )
            )}
        </div>
      </div>
    </div>
  )
}

export default About
